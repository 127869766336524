<template>
  <v-menu location="bottom" content-class="qtm-border">
    <template v-slot:activator="{ props }">
      <qtm-btn
        v-bind="props"
        tertiary
      >
        <v-icon class="mr-2">
          mdi-help-circle-outline
        </v-icon>
        Help
      </qtm-btn>
    </template>
    <v-list>
      <v-list-item @click="toggleFreshChat">
        <template v-slot:prepend>
          <v-icon>
            mdi-forum
          </v-icon>
        </template>
        <v-list-item-title>
          Chat With Us
        </v-list-item-title>
      </v-list-item>
      <v-list-item href="https://quotetome.freshdesk.com/support/home" target="_blank">
        <template v-slot:prepend>
          <v-icon>
            mdi-book-open-variant
          </v-icon>
        </template>
        <v-list-item-title>
          Visit Help Center
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'help-menu',
  data() {
    return {
      freshChatOpen: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  created() {
    this.launchFreshChat()
  },
  methods: {
    launchFreshChat() {
      this.initialize(document, 'freshchat-js-sdk')
    },
    toggleFreshChat() {
      if (this.freshChatOpen) {
        window.fcWidget.close()
      }
      else {
        window.fcWidget.open()
      }
    },
    /* eslint-disable */
    initFreshChat() { // function by freshchat
      window.fcWidget.init({
        token: this.$config.public.FRESHDESK_API_KEY,
        host: 'https://wchat.freshchat.com',
        tags: ['in_app_support', 'suggestions'],
        externalId: this.user.id,
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        email: this.user.email,
        phone: this.user.phone,
        phoneCountryCode: "+1",
        company: this.user.company,
        company_jobtitle: this.user.company_jobtitle,
        config: {
          headerProperty: {
            hideChatButton: true
          },
        }
      })
      // on the widget opening and closing trigger the state of freshchat open or closed.
      let vueContext = this;
      window.fcWidget.on("widget:closed", function(resp) {
        vueContext.freshDeskOpen = false;
      });
      window.fcWidget.on("widget:opened", function(resp) {
        vueContext.freshDeskOpen = true;
      });
    },
    initialize(i, t) { // function by freshchat
      let e
      i.getElementById(t)
        ? this.initFreshChat()
        : ((e = i.createElement('script')).id = t, e.async = !0, e.src = 'https://wchat.freshchat.com/js/widget.js', e.onload = this.initFreshChat, i.head.appendChild(e))

      window.addEventListener
        ? window.addEventListener('load', this.initiateCall, !1)
        : window.attachEvent('load', this.initiateCall, !1)
    },
    initiateCall() { // function by freshchat
      this.initialize(document, 'freshchat-js-sdk')
    }
    /* eslint-enable */
  }
}
</script>
